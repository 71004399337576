import type { RouteObject } from "react-router";
import { useRoutes } from "react-router-dom";
import ApiKeysConfiguration from "~/components/ApiKeysConfiguration/ApiKeysConfiguration";
import ServicesConfiguration from "~/components/ServicesConfiguration/ServicesConfiguration";
import WebhookConfiguration from "~/components/WebhookConfiguration/WebhookConfiguration";
import Layout from "~/components/layout/Layout";
import NoMatch from "~/components/layout/NoMatch/NoMatch";
import Analytics from "../Analytics/Analytics";
import Developers from "../Developers/Developers";
import Login from "../Login/Login";
import Home from "../Home/Home";
import AuthRequired from "~/components/layout/AuthRequired/AuthRequired";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/developers",
        element: (
          <AuthRequired>
            <Developers />
          </AuthRequired>
        ),
        children: [
          { index: true, element: <ApiKeysConfiguration /> },
          { path: "/developers/services", element: <ServicesConfiguration /> },
          { path: "/developers/webhook", element: <WebhookConfiguration /> },
        ],
      },
      {
        path: "/analytics",
        element: (
          <AuthRequired>
            <Analytics />
          </AuthRequired>
        ),
      },
      { path: "*", element: <NoMatch /> },
    ],
  },
];

const App = () => {
  let element = useRoutes(routes);
  return <div className="App">{element}</div>;
};

export default App;
