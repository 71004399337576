import './MenuDrawerMenu.scss';

import { Box } from '@mui/material';

import MenuDrawerMenuButton from '../MenuDrawerMenuButton/MenuDrawerMenuButton';

type MenuDrawerMenuProps = {
  small: boolean;
  items: Array<any>;
};
const MenuDrawerMenu = ({ items, small }: MenuDrawerMenuProps) => {

  return (
    <Box className={'CAKE__menu-drawer-menu'}>
      {items.map((item) => (
        <MenuDrawerMenuButton
          key={item.title}
          title={item.title}
          // link={item.link(projectId)}
          link={'https://google.com'}
          icon={item.icon}
          isActive
          small={small}
        />
      ))}
    </Box>
  );
};

export default MenuDrawerMenu;
