import { Box } from "@mui/material";
import { Outlet } from "react-router";
import MenuDrawer, { MenuDrawerVariant } from "./MenuDrawer";

const Layout = () => {
  return (
    <Box>
      <MenuDrawer variant={MenuDrawerVariant.DASHBOARD} />
      <Outlet />
    </Box>
  );
};

export default Layout;
