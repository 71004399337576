import {
  AnalyticsIcon,
  NotificationsIcon,
} from '~/icons/menu';

const projectMenuItems = [
  {
    title: 'Notifications',
    // linkPattern: PrivateRoutes.DASHBOARD_PROJECT_OVERVIEW,
    link: (projectId: string) => `/dashboard/projects/${projectId}/`,
    icon: <NotificationsIcon />,
  },
  {
    title: 'Analytics',
    // linkPattern: PrivateRoutes.DASHBOARD_PROJECT_ANALYTICS,
    link: (projectId: string) => `/dashboard/projects/${projectId}/analytics`,
    icon: <AnalyticsIcon />,
  },
];

export { projectMenuItems };
