import './MenuDrawerHeader.scss';

import { Box } from '@mui/material';

import { CakeLogo } from '~icons/common';

const MenuDrawerHeader = () => {
  // const { navigate } = useNavigate();
  return (
    <Box className={'CAKE__menu-drawer-header'}>
      <Box
        className={'CAKE__menu-drawer-header__home'}
        // onClick={() => navigate(PrivateRoutes.DASHBOARD_PROJECTS_LIST)}
      >
        <CakeLogo />
      </Box>
    </Box>
  );
};

export default MenuDrawerHeader;
