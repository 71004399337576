import { PropsWithChildren } from "react";
import { Navigate } from "react-router";

type AuthRequireProps = PropsWithChildren<{}>;

const AuthRequired = ({ children }: AuthRequireProps) => {
  const isLoggedIn = true;
  if (isLoggedIn) return <>{children}</>;
  return <Navigate to="/login" replace />;
};

export default AuthRequired;
