import { Outlet } from "react-router";

const Developers = () => {
  return (
    <div>
      <h1>Developers</h1>
      <Outlet/>
    </div>
  );
};

export default Developers;
